import { PageHeaderLinks } from '@/components/common/page-header-links'
import clsx from 'clsx'
import type { ReactNode } from 'react'
import styles from './page-header.module.css'

interface Props {
  articleLink?: string
  children?: React.ReactNode
  text?: string | ReactNode
  title: string
  videoLink?: string
}

export function PageHeader({
  articleLink,
  children,
  text,
  title,
  videoLink,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{title}</h1>
      {!!text && <div className={clsx(styles.text, 'text-3')}>{text}</div>}
      {!!children && (
        <div className={clsx(styles.body, 'text-3')}>{children}</div>
      )}

      <PageHeaderLinks articleLink={articleLink} videoLink={videoLink} />
    </div>
  )
}

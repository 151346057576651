'use client'

import { File, PlayCircle } from '@phosphor-icons/react/dist/ssr'
import { Flex } from '@radix-ui/themes'
import { useTranslations } from 'next-intl'

interface Props {
  articleLink?: string
  videoLink?: string
}

export function PageHeaderLinks({ articleLink, videoLink }: Props) {
  const commonT = useTranslations('common')
  const showArticleLink =
    articleLink != null &&
    articleLink.trim() !== '' &&
    articleLink !== 'pageArticleLink'
  const showVideoLink =
    videoLink != null &&
    videoLink.trim() !== '' &&
    videoLink !== 'pageVideoLink'
  const showLinks = showArticleLink || showVideoLink

  if (!showLinks) {
    return null
  }

  return (
    <Flex gap="4" mt="2">
      {showArticleLink && (
        <a className="link" href={articleLink} target="_blank" rel="noreferrer">
          <File size={20} />
          {commonT('pageArticleText')}
        </a>
      )}

      {showVideoLink && (
        <a className="link" href={videoLink} target="_blank" rel="noreferrer">
          <PlayCircle size={20} />
          {commonT('pageVideoText')}
        </a>
      )}
    </Flex>
  )
}

'use client'

import { PageHeader } from '@/components/common/page-header'
import Logo from '@/components/logo'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import styles from './error.module.css'

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html lang="en">
      <head>
        <title>Oops, you’ve found a bug!</title>
      </head>
      <body>
        {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
        {/* <NextError statusCode={undefined as any} /> */}

        <div className={styles.wrapper}>
          <Logo />

          <PageHeader
            title="Oops, you've found a bug!"
            text="Please leave feedback so we can get it fixed for you as soon as we can. Thank you!"
          />
        </div>
      </body>
    </html>
  )
}

'use client'

interface Props {
  width?: number | string
  height?: number | string
}

export default function Logo({ width = 42, height = 42 }: Props) {
  return (
    <div className="icon">
      <svg
        className="svg"
        focusable="false"
        aria-hidden="true"
        width={width}
        height={height}
        viewBox="0 0 42 42"
      >
        <title>Players 1st logo</title>
        <g>
          <path
            d="M42 20.752c0 11.37-9.402 20.588-21 20.588S0 32.123 0 20.752C0 9.382 9.402.164 21 .164s21 9.218 21 20.588"
            fill="#FF5C3E"
          />
          <path
            d="M12.823 30.695l-.605-.006s1.657-9.755 2.331-13.56c.675-3.804 3.2-5.15 6.332-5.15h.5l-2.396 13.746c-.504 2.891-3.118 5-6.162 4.97M29.101 11.979l-6.496 2.465-.46 2.69h3.463l-1.033 6.075h1.07c2.581 0 4.784-1.903 5.208-4.343l.392-2.31c.322-1.868-.603-3.654-2.144-4.577"
            fill="#FFF"
          />
        </g>
      </svg>
    </div>
  )
}
